/*
This React page uses the Wordpress REST API to build a searchable interface for pages of type 'indicator'
*/
import './Dashboard.css';
import DashboardTopic from './DashboardTopic';
import DashboardIndicators from './DashboardIndicators';
import React, {useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loader from "../util/Loader";

export default function Dashboard(){

    const [categories, setCategories] = useState([]);
    const [categoryActive, setCategoryActive] = useState(-1);
    const [categoryActiveName, setCategoryActiveName] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [indicatorSubCategories, setIndicatorSubCategories] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [indicatorActive, setIndicatorActive] = useState(-1);
    const [chartTitle, setChartTitle] = useState('');
    const [chartLink, setChartLink] = useState('#');
    const [isIndicator, showIsIndicator] = useState(false);
    const baseApiUri = process.env.REACT_APP_API_URL;
    const[open, setOpen] = useState(false);
    const baseAppUri = process.env.REACT_APP_APP_URI;

    //Google tag manager tracking function
    const trackLinkClick = (linkName) => {
        window.dataLayer.push({
          event: "custom_link_click",
          link_name: linkName,
        });
      };
      

    //functions to manage the loading indicator
    const showModal = async () => {
        return setOpen(true);
    }
    
    const hideModal = async () => {
        return setOpen(false);
    }


   const ref = useRef(null);

   
    const showIndicator = async (indicator) => {

        console.log(`showing indicator for`, indicator);

        setIndicatorActive(indicator.id);
        showIsIndicator(true);
        //console.log(baseAppUri);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        console.log('BASE APP URI', baseAppUri);
        let src = baseAppUri+indicator.Chart_url;
        let title = indicator.title;
        let link = indicator.link;
        setChartTitle(title);
        setChartLink(link);
        console.log('ACTIVE INDICATOR', indicatorActive);
        document.getElementById("indicatorFrame").src = src;   
        if(baseAppUri === 'https://ui.vtkidsdata.org'){
            trackLinkClick('Dashboard: Indicator:'+indicator.title);
        } else {
            console.log('FIRING GTA: Indicator: '+indicator.title);
        }    
    }

    const showSubcategory = async (category, id) => {
        console.log(`Dashboard showing subcategory for ${category}`);
        console.log('Dashboard sub categories', subCategories);
        console.log('Dashboard clicked element', id);
        setCategoryActive(id);
        setCategoryActiveName(`/ ${category}`)
        //document.getElementById(elt);
        let filteredSubCategories = subCategories.filter(function (el) {
            //console.log(el);
            return el[category] === 1;
        }
        );
        console.log('FILTERED SUB CATEGORIES', filteredSubCategories);
        setIndicatorSubCategories(filteredSubCategories);
        if(baseAppUri === 'https://ui.vtkidsdata.org'){
            trackLinkClick('Dashboard: Topic:'+category);
        } else {
            console.log('FIRING GTA: Topic: '+category);
        }
       
        
    }

    useEffect( () => {

        const getDashboard = async () => {

            const getCategories = async () => {

                //let endpoint = 'http://localhost:4000/dashboard/categories';
                let endpoint = `${baseApiUri}/table/table/dashboard:categories:table`;
                let resp = await axios.get(endpoint);
                console.log('CAT', resp.data);
                return resp.data.rows;
                
        
            }
        

            const getTopics = async () => {
                showModal();
                //let topicsEndpoint = 'http://localhost:4000/dashboard/topics';
                let topicsEndpoint = `${baseApiUri}/table/table/dashboard:topics:table`;
                let resp = await axios.get(topicsEndpoint);
                //console.log('TOPICS',resp.data);
                setTopics(resp.data.rows);
                return resp.data.rows.reverse();
            }

            const getIndicators = async (s) => {

                //console.log(isIndicator);
                //let endpoint = 'http://localhost:4000/dashboard/indicators';
                let endpoint = `${baseApiUri}/table/table/dashboard:indicators:table`;
                let resp = await axios.get(endpoint);
                //for each of the indicators, add them to the proper subcategories
                /*
                let subCategoryIndicators = {};
                //console.log('SUB CATEGORIES', s);
                for(let i = 0; i < subCategories.length; i++){
                    let subcategory = subCategories[i];
                    //console.log('SUB CATEGORIES (Integrations)', subcategory);
                }
                */
                //console.log('SUB CAT INDICATORS',subCategoryIndicators);
                hideModal();
                return resp.data.rows;
                
            }

            const getSubCategories = async () => {

                //let endpoint = 'http://localhost:4000/dashboard/subcategories';
                let endpoint = `${baseApiUri}/table/table/dashboard:subcategories:table`;
                let resp = await axios.get(endpoint);
                //let s = resp.data;
                //put the subcategories into an object we can filter on
                return resp.data.rows;
                
                //console.log('TOPICS',topics);
            }

            //get topics
            let t = await getTopics();
            setTopics(t);
            //get categories
            let c = await getCategories();
            c.sort(function(a, b) {
                var textA = a.Category.toUpperCase();
                var textB = b.Category.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            //console.log('CATEGORIES', c);
            setCategories(c);
            //get subcategories  
            let s = await getSubCategories();
           // console.log('SUB CATEGORIES', s);
            setSubCategories(s);
             //get subcategories  
             let i = await getIndicators(s);
             console.log('INDICATORS', i);
             setIndicators(i);
             
            //console.log('TOPICS',topics);          
           // console.log('CATEGORIES', topicCategories);
        }

        getDashboard();
    }, [isIndicator, baseApiUri])

    return (

        <Box sx={{padding: '20px'}}>
            <Loader open={open} />
                <div className='dashboard-container' id="topics" >
                    <Grid container spacing={2}>
                        <Grid item xs={4} className='topic-grid'>
                        {topics.map((t, index) => (
                            <div key={index}>
                            <div className='vecap-container-subheading vecap-bold'>{t.name}</div>
                            <div className="vecap-list">                     
                                <div>
                                    <DashboardTopic categoryActive={categoryActive}  topic={t} categories={categories} showSubcategory={showSubcategory} key={index} /></div>                      
                            </div>
                            </div>
                        ))} 
                        </Grid>
                        <Grid item xs={8}>
                            <div className='indicator-heading'>Indicators {categoryActiveName}</div>
                            {indicatorSubCategories.map((i, index) => (
                             
                                
                                    <DashboardIndicators key={index} indicatorActive={indicatorActive} showIndicator={showIndicator} indicators={indicators} subcategory={i.Category} />
                               

                            ))}
                        </Grid>
                    </Grid>

                    </div>

                   
                    <div ref={ref} className='dashboard-container' id="indicators" >

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='indicator-iframe-heading'><a  rel="noreferrer" target="_blank" className='indicator-iframe-heading-link' href={chartLink}>{chartTitle}</a></div>
                            <div>
                            <iframe 
                                className="indicator-frame"
                                id="indicatorFrame"                                
                                height="500" 
                                width="100%" 
                                title="Indicator"
                                >
                                </iframe>
                            </div>
                        </Grid>
                    </Grid>

                    </div>
                    
                    
            </Box>    

    );
}